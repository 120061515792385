<template>
  <header class="header"
          :class="{'is-active' : isActiveHeader ,'is-sub' : !isMain , 'show-menu-list' : viewMenuList.isMenuShow, 'no-border' : isMain }">
    <div class="inner top-inner">
      <div>
        <!-- [ Left ] 로고 -->
        <div class="logo-area" @click="$router.push('/portal/user/main/user_main')">
          <img v-show="!isLogoWhite" src="@/assets/images/common/logo/logo.png"
               alt="강원특별자치도교육청교육과학정보원 메인 이동 로고"/>
          <img v-show="isLogoWhite" src="@/assets/images/common/logo/logo_white.png"
               alt="강원특별자치도교육청교육과학정보원 메인 이동 로고"/>
        </div>

        <!-- [ Right ] 버튼 -->
        <div class="btn-area">
          <div v-if="!isLoggedIn">
            <!-- 로그인 버튼 -->
            <div class="el-button btn-login fw-400 is-transparent el-button--pale">
              <nuxt-link to="/portal/user/login/user_login">
                <span class="txt">로그인</span>
              </nuxt-link>
            </div>

            <!-- 회원가입 버튼 -->
            <div class="el-button btn-login fw-400 is-transparent el-button--pale">
              <nuxt-link to="/portal/user/join/join_main">
                <span class="txt">회원가입</span>
              </nuxt-link>
            </div>
          </div>

          <div v-if="isLoggedIn" class="login-button__area">
            <!-- 유저이름 -->
            <div class="el-button btn-login fw-400 is-transparent el-button--pale no-cursor">
              <span class="color-lthird">
                <span class="">{{ loginUser.userName }}</span>님
              </span>
            </div>

            <!-- 관리자 버튼 -->
            <div v-if="isAdmin" class="el-button btn-login fw-400 is-transparent el-button--pale">
              <nuxt-link :to="{ path: firstAdminChildMenu }">
                <span class="txt">관리자</span>
              </nuxt-link>
            </div>

            <!-- SMS 발송 버튼 -->
            <div v-if="isSmsManager" class="el-button btn-login fw-400 is-transparent el-button--pale">
              <nuxt-link to="/portal/user/sendSms/sendSmsPage/sendSmsPage">
                <span class="txt">SMS발송</span>
              </nuxt-link>
            </div>

            <!-- 마이페이지 버튼 -->
            <div class="el-button btn-login fw-400 is-transparent el-button--pale">
              <nuxt-link to="/portal/user/myPage/personalInfo/personalInfo_edit">
                <span class="txt">마이페이지</span>
              </nuxt-link>
            </div>

            <!-- 구글스페이스 버튼 -->
            <div v-if="isGwStaff" class="el-button btn-login fw-400 is-transparent el-button--pale">
              <!-- 워크스페이스 회원 : userMgmt_list -->
              <!-- 워크스페이스 비회원 : manageMy_information -->
              <nuxt-link :to="isWorkspaceOwner ? '/portal/user/userWorkspace/userMgmt_list' :
                                                 '/portal/user/userWorkspace/manageMy_information'">
                <span class="txt">구글 워크스페이스</span>
              </nuxt-link>
            </div>

            <!-- MS 계정 만들기 버튼 -->
            <div v-if="isAdmin || isGwStaff || isStudent" class="el-button btn-login fw-400 is-transparent el-button--pale">
              <a href="https://office365.gwe.go.kr/" target="_blank">MS계정 만들기</a>
            </div>

            <!-- 로그아웃 버튼 -->
            <div class="el-button btn-login fw-400 is-transparent el-button--pale">
              <div class="link">
                <span class="txt" @click="logout()">로그아웃</span>
              </div>
            </div>

            <!-- 최근 접속 날짜 및 시간 -->
            <div class="recently-accessed__wrapper">
              <div class="recently-accessed__inner">
                <div class="recently-accessed__information">
                  <span>최근 접속 : </span>
                  <span>{{ getLastLoginDate() }}</span>
                </div>
              </div>
            </div>
            <!--             로그인 시간 및 연장 버튼 -->
            <div class="extended-time__wrapper">
              <div class="extended-time__inner">
                <div class="extended-time">
                  <span class="resTime" v-text="resTime"></span>
                </div>
                <div class="extended-time__button">
                  <el-button class="size-smd" type="" @click="resetTimer()">
                    시간 연장
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner bottom-inner" @mouseleave="hideMenuList">
      <!-- menu-area -->
      <div class="menu-area"
      >
        <!-- nav :: gnb (주메뉴 리스트만 보여줌 -->
        <nav class="gnb"
             @mouseover="showMenuList"
        >
          <ul class="gnb-menu">
            <li v-for="(menu , i) in menuList" :key="i" :class="{'is-active' : menu.active}">
              <a class="main-menu-link" v-text="menu.name"
                 @click="moveGnbMenu(menu)"
                 @mouseover="setGnbMenuList(menu)"></a>
            </li>
            <li
              v-if=" $validate.isNotEmpty( tempLayerPopupList ) || $validate.isNotEmpty( tempPopupZoneList )">
              <a class="main-menu-link" @click="openPopup">POPUP ZONE</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- buttons -->
      <div class="btn-area">
        <el-button type="pale" class="web-visible is-transparent is-circle shade-bg size-md"
                   @click="addMediaClass">
          <span class="material-icons xlg">menu</span>
        </el-button>
        <!-- mob-menu-btn -->
        <el-button type="pale" class="mob-visible is-transparent is-circle shade-bg size-md"
                   @click="[onMobMenuToggle( true, 'hidden')]">
          <span class="material-icons xlg">menu</span>
        </el-button>
      </div>
      <!-- 메뉴를 뿌려주는 곳
        leave-active-class="fadeOutUp"
      -->
      <!--      v-show="viewMenuList.isMenuShow"-->
      <div
        class="menu-list-area"
        :class="viewMenuList.isMenuShow ? 'is-show' : '' "
        style="animation-duration: 0.5s">
        <div class="inner">
          <!-- 메뉴뿌려줌 -->
          <nav class="gnb-list">
            <!-- 1 depth -->
            <div class="main-depth">
              <a
                class="main-menu-link"
                v-text="viewMenuList.currentMenu.name"
                @click="moveMenu( viewMenuList.currentMenu )"
              >
              </a>
            </div>
            <!-- 2 depth 이상 -->
            <div class="sub-depth">
              <!--  최대 리스트 갯수 5개 그이상이되면 다음 sub-menu에 뿌려줘야됨 -->
              <!-- 2depth -->
              <ul class="sub-menu is-depth2" v-for="(subList , i) in viewMenuList.childTreeNodeList"
                  :key="i">
                <li v-for="(depth2 ,index) in subList" :key="index">
                  <a
                    class="sub-menu-link"
                    @click="moveSubMenu( viewMenuList.currentMenu, depth2, 2 )"
                  >
                    <!-- depth2 name -->
                    <span v-text="depth2.name"></span>
                    <!-- 링크 연결 메뉴 표시 icon -->
                    <span v-if="isLink( depth2 )" class="material-icons page-link">launch</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <!-- 모바일 메뉴 -->
    <div class="mob-menu-area mob-visible" :class="mobMenuSlideYn ? 'is-opened' : 'is-closed'">
      <div class="inner">
        <!-- 모바일 메뉴 상단 -->
        <div class="content-header">
          <div class="user-area">
            <!-- 로그인 전 -->
            <div class="user-login" v-if="!isLoggedIn">
              <nuxt-link to="/portal/user/login/user_login">
                <span class="txt" @click="[onMobMenuToggle( false, '')]">로그인</span>
              </nuxt-link>
              <nuxt-link to="/portal/user/join/join_main">
                <span class="txt" @click="[onMobMenuToggle( false, '')]">회원가입</span>
              </nuxt-link>
              <nuxt-link
                to="/portal/user/customerService/privatePolicy/personalInformation/personalInformation">
                <span class="txt" @click="[onMobMenuToggle( false, '')]">고객센터</span>
              </nuxt-link>
            </div>
            <!-- 로그인 후 -->
            <div class="user-info" v-if="isLoggedIn">
              <div class="user-name-group">
                <div class="user-name">
                  <span class="">{{ loginUser.userName }}</span>님
                </div>
                <nuxt-link to="/portal/user/myPage/personalInfo/personalInfo_edit"
                           class="setting-btn">
                  <span class="material-icons-outlined" @click="[onMobMenuToggle( false, '')]">settings</span>
                </nuxt-link>
              </div>
              <div class="user-btn-group">
                <div class="cs-btn">
                  <nuxt-link
                    to="/portal/user/customerService/privatePolicy/personalInformation/personalInformation">
                    <span class="txt" @click="[onMobMenuToggle( false, '')]">고객센터</span>
                  </nuxt-link>
                </div>
                <div class="user-logout">
                  <span class="txt" @click="logout()">로그아웃</span>
                </div>
              </div>
            </div>
            <!-- mob-menu-close-btn -->
            <el-button type="pale" class="is-transparent close-btn"
                       @click="[onMobMenuToggle( false, ''),]">
              <span class="material-icons-outlined">close</span>
            </el-button>
          </div>
          <!-- 모바일 메뉴 검색창 -->
          <the-main-search/>
        </div>

        <!-- 모바일 메뉴 하단 -->
        <div class="content-body">
          <nav
              ref="mobGnbRef"
              class="mob-gnb"
          >
            <ul class="gnb-menu is-depth1">
              <li v-for="(menu , i) in menuList" :key="i" :class="{'is-active' : menu.active}">
                <ul class="sub-menu is-depth2">
<!--                  :class="[ depth2.childTreeNodeList.length > 0 ? mobToggle2 && depth2.oid === selected2 ? 'is-opened' : 'is-closed' : '' ]"-->
                  <li
                    v-for="(depth2 , subI) in menu.childTreeNodeList"
                    :key="i + subI"
                    :class="setMobileGnbSelectMenuActive( depth2.childTreeNodeList.length, mobToggle2, depth2.menuOid, selected2 )"
                    @click.stop="onSubMenuClick( menu , depth2 )">
                    <a
                      class="sub-menu-link"
                      :class="[ ( depth2.menuOid === activeSelected && !isActiveRemove ) ? 'is-active' : '' ]"
                    >
                      <!-- depth2 name -->
                      <span v-text="depth2.name"></span>
                      <!-- 링크 연결 메뉴 표시 icon -->
                      <span v-if="isLink( depth2 ) " class="material-icons page-link">launch</span>
                    </a>
                    <!-- 3뎁스 메뉴 :: 4뎁스 없으면 클릭시 페이지 이동, 4뎁스 있으면 class 'is-closed' 추가 & 클릭시 클래스 'is-opened'로 변경 -->
                    <ul class="sub-menu is-depth3"
                        v-if="depth2.childTreeNodeList && depth2.childTreeNodeList.length > 0">
                      <li
                        v-for="(depth3 , i3) in depth2.childTreeNodeList"
                        :key="i3"
                        :class="[ depth3.childTreeNodeList.length > 0 ? mobToggle3 && depth3.menuOid === selected3 ? 'is-opened' : 'is-closed' : '']"
                      >
                        <a
                          class="sub-menu-link"
                          :class="[ ( depth3.menuOid === activeSelected && !isActiveRemove ) ? 'is-active' : '']"
                          @click.stop="moveSubMenu( depth2, depth3, 3 )"
                        >
                          <!-- depth3 name -->
                          <span v-text="depth3.name"></span>
                          <!-- 링크 연결 메뉴 표시 icon -->
                          <span v-if="isLink( depth3 ) "
                                class="material-icons page-link">launch</span>
                        </a>

                        <!-- 4뎁스 메뉴 -->
                        <ul class="sub-menu is-depth4"
                            v-if="depth3.childTreeNodeList && depth3.childTreeNodeList.length > 0">
                          <li
                            v-for="(depth4 , i4) in depth3.childTreeNodeList"
                            :key="i4">
                            <a
                              class="sub-menu-link"
                              :class="[ ( depth4.menuOid === activeSelected && !isActiveRemove ) ? 'is-active' : '' ]"
                              @click.stop="moveSubMenu( depth3, depth4, 4 )"
                            >
                              <!-- depth4 name -->
                              <span v-text="depth4.name"></span>
                              <!-- 링크 연결 메뉴 표시 icon -->
                              <span v-if="isLink( depth4 ) "
                                    class="material-icons page-link">launch</span>
                            </a>
                          </li>
                        </ul>

                      </li>
                    </ul>

                  </li>
                </ul>
                <!-- 2뎁스 메뉴 :: 3뎁스 없으면 클릭시 페이지 이동, 3뎁스 있으면 class 'is-closed' 추가 & 클릭시 클래스 'is-opened'로 변경 -->
                <a
                  class="main-menu-link"
                  v-html="menu.name"
                  @click="setGnbMenuList( menu )"
                ></a>
              </li>
            </ul>
          </nav>
        </div>

      </div>
    </div>
    <!-- 모바일 blank -->
    <div class="mob-menu-blank" v-show="mobMenuSlideYn" @click="onMobMenuToggle( false, '')"></div>

    <!-- 사이트 맵 -->
    <transition name="fade">
      <!-- 사이트맵 -->
      <the-site-map v-show="sitemapVisible"
                    style="animation-duration: 0.5s">
        <!-- options -->
        <div slot="options" class="option-group">
          <!-- close button -->
          <el-button type="black" class="close-btn is-change size-lg is-transparent"
                     @click="sitemapVisible = false">
            <span class="material-icons xlg">close</span>
          </el-button>
        </div>
        <!-- 사이트맵 메뉴 -->
        <ul slot="snb-menu" class="snb-menu">
          <!-- 1뎁스 메뉴에용 -->
          <li v-for="(menu , i) in menuList" :key="i">
            <a
              class="site-menu-link"
              v-text="menu.name"
              @click="moveMenu( menu )"
            >
            </a>
            <!-- 좌우로 갈라질건지 is-division -->
            <ul class="sub-menu" :class="menu.options">
              <!-- for -->
              <!-- 2뎁스 메뉴에용 -->
              <li v-for="(depth2 , subI) in menu.childTreeNodeList" :key="i + subI">
                <a
                  class="sub-menu-link"
                  @click="moveSubMenu( menu, depth2, 2 )"
                >
                  <!-- depth2 name -->
                  <span v-text="depth2.name"></span>
                  <!-- 링크 연결 메뉴 표시 icon -->
                  <span v-if="isLink( depth2 )" class="material-icons page-link">launch</span>

                </a>
                <ul v-if="depth2.childTreeNodeList && depth2.childTreeNodeList.length > 0"
                    class="sub-menu">
                  <!-- 3뎁스 메뉴에용 -->
                  <li v-for="(depth3 , i3) in depth2.childTreeNodeList" :key="i3">
                    <!-- 과학교육일시 is-primary , is-point 추가 -->
                    <a
                      class="sub-menu-link"
                      :class="depth3.options"
                      @click="moveSubMenu( depth2, depth3, 3 )"
                    >
                      <!-- depth3 name -->
                      <span v-text="depth3.name"></span>
                      <!-- 링크 연결 메뉴 표시 icon -->
                      <span v-if="isLink( depth3 )" class="material-icons page-link">launch</span>
                    </a>
                    <ul v-if="depth3.childTreeNodeList && depth3.childTreeNodeList.length > 0"
                        class="sub-menu">
                      <!-- 4뎁스 메뉴에용 -->
                      <li v-for="(depth4 , i4) in depth3.childTreeNodeList" :key="i4">
                        <a class="sub-menu-link"
                           @click="moveSubMenu( depth3, depth4, 4 )"
                        >
                          <!-- depth4 name -->
                          <span v-text="depth4.name"></span>
                          <!-- 링크 연결 메뉴 표시 icon -->
                          <span v-if="isLink( depth4 )"
                                class="material-icons page-link">launch</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul v-if="0===i">
              <li>
                <a
                  class="site-menu-link"
                  v-text="'교직원집합연수'"
                  @click="$nuxt.$router.push('/portal/user/common/commonScreen?menuOid=1TRvaqvX01E'), sitemapVisible = false;"
                ></a>
                <ul class="sub-menu">
                  <li class="ml17">
                    <a
                      class="sub-menu-link"
                      v-text="'연간연수일정'"
                      @click="$nuxt.$router.push('/portal/user/common/commonScreen?menuOid=1TRvaqvX01E'), sitemapVisible = false;"
                    ></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </the-site-map>
    </transition>
    <!-- 팝업 (레이어) -->
    <the-layer-popup
      :popup-list="layerPopupList"
      :visible="layerPopupVisible"
      :ignore-cookie="true"
      @close="closeLayerPopup"
    />
    <the-popup-zone
      :popup-list="popupZoneList"
      :ignore-cookie="true"
      class="is-fixed"
      @close="closePopupZone"
    />
  </header>
</template>
<script>
import {mapGetters} from "vuex";
import TheSiteMap from "~/components/portal/user/layout/TheSiteMap";
import TheMainSearch from "~/components/portal/user/main/TheMainSearch";
import TheLayerPopup from "~/components/common/popup/TheLayerPopup.vue";
import ThePopupZone from "~/components/common/popup/ThePopupZone.vue";
import froalaEditorUtil from "~/utils/site/portal/froala_editor_util";
import portalApiUrl from '~/constant/site/portal/portalApiUrl';


export default {
  name: "TheHeader",
  components: {
    TheMainSearch,
    TheSiteMap,
    TheLayerPopup,
    ThePopupZone
  },
  props: {
    isMain: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      // 스크롤 관련
      breakPoint: 50,
      scrollY: 0,
      scrollTimer: null,
      isMainScroll: false,

      // navMouseOver 관련
      isInnerHover: false,

      // 사이트맵
      sitemapVisible: false,

      // 보여줄 메뉴리스트
      viewMenuList: {
        // 메뉴 보여주고 숨김처리
        isMenuShow: false,
        // 현재메뉴 정보
        currentMenu: {},
        // 2뎁스 메뉴리스트
        childTreeNodeList: [],
        // 3뎁스 메뉴리스트
        childTreeTwoDepthNodeList: [],
      },

      /* 모바일 */
      selected2: '',
      selected3: '',
      // 페이지 이동시 sub-menu-link에 is-active 셋팅
      activeSelected: '',

      mobToggle2: false,
      mobToggle3: false,

      userMenuList: [],

      // 모바일 메뉴 슬라이드
      mobMenuSlideYn: false,

      // 모바일 환경인지 검사
      isMobile: this.$validate.isMobile(),

      // 남은 세션 시간
      resTime: '',

      // 임시로 팝업 리스트를 담기 위해 사용합니다.
      tempLayerPopupList: [],
      tempPopupZoneList: [],

      // 팝업
      layerPopupList: [],
      popupZoneList: [],

      layerPopupVisible: false,

      // 모바일 사이즈
      isMobileMode: this.$constant.MOBILE_SIZE.WIDTH >= window.innerWidth,

      isActiveRemove : false,
    }
  },

  computed: {

    ...mapGetters( {
      userMenuListByRole: "portal/menuRole/userMenuListByRole",
      beforeLoginUserMenuListByRole: "portal/menuRole/beforeLoginUserMenuListByRole",
      adminMenuListByRole: "portal/menuRole/adminMenuListByRole",
      loginUser: "common/login/loginUserInfo",
    } ),

    /**
     * - 다음의 경우에 로고를 변경합니다
     *
     * - white
     *
     * 1. scroll 이 화면 최상단에 있을 경우,
     *
     * 2. header 의 높이보다 더 스크롤 하고, 해당 header 를 hover 하지 않은 경우
     *
     * - black
     *
     * 1. 메인 페이지가 아닐경우,
     *
     * 2. 현재  header 의 높이보다 더 스크롤 했을 경우
     *
     * 3. header 를 hover 했을 경우
     *
     * @returns {boolean} - white 로고 여부
     */
    isLogoWhite() {
      /** black */
      if ( !(this.isMain) || this.scrollY > this.breakPoint || this.isInnerHover ) {
        return false;
      }
      /** white */
      else if ( 0 === window.scrollY || this.scrollY < this.breakPoint && this.isInnerHover ) {
        return true;
      }
      return false;
    },

    // 로그인되어있으면 true
    isLoggedIn() {
      return this.$portalAuth.isLogin();
    },
    // 관리자라면 true
    isAdmin() {
      return this.loginUser.isManager;
    },

    // sms 발송 권한이 있다면 true
    isSmsManager() {

      if ( this.$portalAuth.isNotLogin() ||  this.$validate.isEmpty( this.userMenuListByRole ) ) {
        return false;
      }

      let result = false;
      // console.log( this.loginUser.userRoleOids);
      return this.loginUser.userRoleOids.includes( this.$constant.USER_SEND_SMS.OID );
      // this.loginUser.userRoleOids.forEach( roleOid => {
      //
      //   if ( this.$constant.USER_SEND_SMS.OID === roleOid ) {
      //
      //     result = true;
      //     return;
      //   }
      // } )
      //
      // return result;
    },

    // 로그인 되어있고, 강원도 교직원이면 true
    isGwStaff() {
      let isGWStaff = this.loginUser.isManager || this.$constant.USER_TYPE.STAFF_GANGWON.KEY
        === this.loginUser.userType;
      return this.isLoggedIn && isGWStaff;
    },

    // 로그인 되어있고, 학생이라면 true
    isStudent() {
      return this.isLoggedIn && this.$constant.USER_TYPE.STUDENT.KEY === this.loginUser.userType;
    },
    // 워크스페이스 사용자이면 true
    isWorkspaceOwner() {
      return this.isGwStaff && this.$validate.isNotEmpty( this.loginUser.googleUserInfo );
    },

    // 사용자 메뉴 리스트
    menuList() {

      // 복제할 메뉴 리스트
      let cloneUserMenuList = [];

      if ( this.$portalAuth.isLogin() ) {

        // 로그인 후 권한에 따른 메뉴 list
        cloneUserMenuList = _.cloneDeep( this.userMenuListByRole );
      }
      else {

        // 로그인 전 메뉴 list
        cloneUserMenuList = _.cloneDeep( this.beforeLoginUserMenuListByRole );
      }

      // 모바일일경우에만, 교직원 집합연수 메뉴가 보여야 함
      if ( this.isMobileMode ) {

        let obj = {
          name: "교직원집합연수",
          url: "/portal/user/common/commonScreen?menuOid=1TRvaqvX01E",
        }

        cloneUserMenuList.push( obj );
      }

      this.setMenuOptions( cloneUserMenuList );

      // display가 Y인 값만 가져옵니다.
      return this.$portalUtil.portalRoleMenuUtils.getOnlyDisplayMenuList( cloneUserMenuList, 1 );
    },

    isActiveHeader() {
      if ( this.isMainScroll ) {
        return true;
      }
      else if ( this.isInnerHover ) {
        return true;
      }
      else {
        return false;
      }
    },

    firstAdminChildMenu() {

      return this.$portalUtil.portalMenuUtils.getFirstAdminMenu();
    },

  },
  watch: {
    sitemapVisible( newV ) {
      if ( newV ) {
        document.querySelector( 'html' ).style.overflow = 'hidden';
      }
      else {
        document.querySelector( 'html' ).style.overflow = '';
      }
    },

    // 모바일 메뉴
    mobMenuSlideYn: {
      deep: true,
      handler() {
        this.$nuxt.$emit( 'test', this.mobMenuSlideYn );
      }

    },

    $route( to, from ) {
      this.removeMobileGnbMenuActive( to.path );
    }
  },
  async fetch() {

    // 모바일일경우 scroll 항상 true
    if ( this.isMobile ) {
      this.scroll = true;
      this.setScreenSize();
    }

    // 팝업
    await this.listPopup();
  },

  created() {

    this.$util.sessionTimeUtils.getListAllEnvConfigCommon();
    this.removeMobileGnbMenuActive( this.$route.path );
  },

  mounted() {
    const vue = this;
    if ( this.isMobile ) {
      // console.log('========================================================= mobile!!')
      window.addEventListener( 'resize', this.$util.eventUiUtils.optimizeAnimation( this.setScreenSize ) );
      window.addEventListener( 'scroll', this.$util.eventUiUtils.optimizeAnimation( this.onScroll ) );
    }
    else {
      // console.log('========================================================= web!!')

      document.addEventListener( 'scroll', function ( event ) {

        let val = document.querySelector( 'html' ).scrollTop;
        if ( val > 0 ) {
          vue.isMainScroll = true;
          vue.isInnerHover = true;
        }
        else {
          vue.isMainScroll = false;
          vue.isInnerHover = false;
        }
      } );
    }

    if ( this.isLoggedIn ) {
      this.getSessionTimeout();
    }

  },
  beforeDestroy() {
    window.removeEventListener( "scroll", this.onScroll );

    clearInterval( this.polling );
  },
  methods: {
    setMobileGnbSelectMenuActive ( listNumber, mobToggles, menuOid, selected ) {
      if ( this.$validate.isEmpty( listNumber ) ) {
        return "";
      }

      return ( mobToggles &&  menuOid === selected ) ? "is-opened" : "is-closed";
    },

    removeMobileGnbMenuActive ( path ) {
      if ( !this.isMobile || this.$validate.isEmpty( path ) || !path.includes("user_main") ) {
        return ( this.isActiveRemove = false);
      }

      this.isActiveRemove = true;

      if ( this.$validate.isEmpty( this.$refs.mobGnbRef ) ) {
        return;
      }

      const openSelectEl = this.$refs.mobGnbRef.querySelector(".is-opened");

      openSelectEl?.classList?.replace("is-opened", "is-closed");
    },

    onScroll( e ) {
      // console.log('-------------------------------------> onScroll!!!!!!!')

      let val = window.scrollY;

      if ( val > 0 ) {
        this.isMainScroll = true;
        this.isInnerHover = true;
      }
      else {
        this.isMainScroll = false;
        this.isInnerHover = false;
      }

    },

    async moveGnbMenu( menu ) {
      if ( this.$validate.isEmpty( menu ) || this.$validate.isEmpty( menu.childTreeNodeList ) ) {
        this.$portalUtil.portalMenuUtils.alertNoMenuInfo( menu.name );
        return;
      }

      // console.log( "moveGnbMenu", menu );
      await this.moveMenu( menu.childTreeNodeList[0] );
    },

    async moveMenu( menu ) {
      // console.log( "moveMenu", menu.name )
      // 타입 없음
      if ( this.$constant.MENU_TYPE_FLAG.EMPTY.KEY === menu.menuTypeFlag ) {
        // 하위 메뉴가 없을 경우
        if ( this.$validate.isEmpty( menu.childTreeNodeList ) ) {
          this.$portalUtil.portalMenuUtils.alertNoMenuInfo( menu.name );
          return;
        }
        // 하위 메뉴가 있을 경우
        else {
          await this.moveSubMenu( menu, menu.childTreeNodeList[0], 2 );
        }
      }

      // URL 타입
      if ( this.$constant.MENU_TYPE_FLAG.URL.KEY === menu.menuTypeFlag ) {

        // 하위 메뉴가 없을 경우
        if ( this.$validate.isEmpty( menu.childTreeNodeList ) ) {
          // 이동할 URL이 없을 경우
          if ( this.$validate.isEmpty( menu.url ) ) {
            this.$portalUtil.portalMenuUtils.alertNoMenuInfo( menu.name );
            return;
          }
          // 해당 메뉴의 URL으로 이동
          else {

            this.$router.push( {path: menu.fullPathUrl} );
          }
        }
        // 하위 메뉴가 있을 경우
        else {
          await this.moveSubMenu( menu, menu.childTreeNodeList[0], 2 );
        }
      }

      // 게시판 타입, 화면 타입, 혼합 타입
      if ( this.$constant.MENU_TYPE_FLAG.BOARD.KEY === menu.menuTypeFlag
          || this.$constant.MENU_TYPE_FLAG.SCREEN.KEY === menu.menuTypeFlag
          || this.$constant.MENU_TYPE_FLAG.MIX.KEY === menu.menuTypeFlag ) {

          this.goMenuItem( menu );
      }
    },
    async moveSubMenu( menu, subMenu, depth ) {
      // console.log('%%%%%%%%%% moveSubmenu',menu, subMenu, depth)

      if ( this.$validate.isEmpty( subMenu.childTreeNodeList ) ) {

        this.activeSelected = subMenu.menuOid;

        // URL 타입
        if ( this.$constant.MENU_TYPE_FLAG.URL.KEY === subMenu.menuTypeFlag ) {
          if ( this.$validate.isEmpty( subMenu.url ) ) {
            this.$portalUtil.portalMenuUtils.alertNoMenuInfo( subMenu.name );
            return;
          }

          if ( -1 < subMenu.url.indexOf( "http://" ) || -1 < subMenu.url.indexOf( "https://" ) ) {
            window.open( subMenu.url );
            return;
          }

          this.$router.push( {path: subMenu.fullPathUrl} );
        }

        // 게시판 타입, 화면 타입, 혼합 타입
        if ( this.$constant.MENU_TYPE_FLAG.BOARD.KEY === subMenu.menuTypeFlag
            || this.$constant.MENU_TYPE_FLAG.SCREEN.KEY === subMenu.menuTypeFlag
            || this.$constant.MENU_TYPE_FLAG.MIX.KEY === subMenu.menuTypeFlag ) {

            this.goMenuItem( subMenu );
        }

        this.viewMenuList.isMenuShow = false;
        this.sitemapVisible = false;
        this.onMobMenuToggle( false, '' );
      }
      else {
        // 자식 노드가 있는 3뎁스인 경우
        if ( 3 === depth ) {
          if ( this.selected3 === subMenu.menuOid && this.isMobile ) {
            this.mobToggle3 = !this.mobToggle3
            return;
          }

          this.selected3 = subMenu.menuOid;
          // 열려있는 3 depth opend 다 닫기
          menu.childTreeNodeList.forEach( threeDepMenu => threeDepMenu.active = false );
          this.mobToggle3 = true;
        }

        // 모바일의 경우 이동하지 않고 collapse만 열어야 하기 때문에 타지 않음
        if ( !this.isMobile ) {
          // console.log('하위에 자식 노드가 있음!!! moveSubMenu로 이동' )
          await this.moveSubMenu( subMenu, subMenu.childTreeNodeList[0], depth + 1 );
          return;
        }

      }
    },

    // 메뉴 item으로 이동 ( 게시판, 화면, 혼합형 )
    goMenuItem( menu ) {

        this.$portalUtil.portalMenuUtils.getMenuItemUrl( menu );
    },

    // 메뉴 보여주고 숨기기
    showMenuList() {
      this.isInnerHover = true;
      this.viewMenuList.isMenuShow = true;
    },

    hideMenuList() {
      this.isInnerHover = false;
      this.viewMenuList.isMenuShow = false;
      // eslint-disable-next-line no-return-assign

      if ( this.$validate.isEmpty( this.menuList ) ) {
        return;
      }

      if ( !this.isMobile ) {
        this.menuList.forEach( allMenu => allMenu.active = false );
      }
    },

    // 1depth 클릭시
    setGnbMenuList( menu ) {

      // console.log("menu",menu)
      this.viewMenuList.currentMenu = menu;

      if ( this.$validate.isEmpty( this.menuList ) ) {
        return;
      }

      // 모든 메뉴 active 초기화
      this.menuList.forEach( allMenu => allMenu.active = false );

      menu.active = true;

      let list = [];
      let childList = [];

      // 모바일에서 교직원 집합연수 클릭시
      if ( this.$validate.isEmpty( menu.childTreeNodeList ) && this.$validate.isNotEmpty( menu.url ) ) {
        this.onMobMenuToggle( false, '' );
        this.$router.push( menu.url );
        return;
      }

      list = this.setSliceListOfFours( menu.childTreeNodeList );
      // list = this.setSliceListOfFours( tempList );

      list.forEach( innerList => {
        // eslint-disable-next-line array-callback-return
        childList = innerList.filter( item => {
          if ( item.childTreeNodeList ) {
            return item.childTreeNodeList;
          }
        } );
      } );

      // 자식들에 설정
      this.viewMenuList.childTreeNodeList = list;
      this.viewMenuList.childTreeTwoDepthNodeList = childList;
    },

    setSliceListOfFours( menuList ) {

      let rest = Math.floor( menuList.length % 5 );
      let cycleNum = Math.floor( menuList.length / 5 );

      let list = [];

      if ( rest !== 0 ) {
        cycleNum += 1;
      }
      // cycleNum = 3
      for ( let i = 0; i < cycleNum; i++ ) { // i = 3
        if ( i === (cycleNum - 1) ) {
          list[i] = menuList.slice( i * 5 );
        }
        else {
          list[i] = menuList.slice( i * 5, ((i * 5) + 5) );
        }
      }

      return list;
    },

    setMobDefaultMenuActive: function ( menuList ) {
      let cnt = 0;
      menuList.forEach( menu => {

        if ( menu.hasOwnProperty( 'active' ) ) {
          cnt += 1;
        }

      } )
      if ( this.$validate.isNotEmpty( menuList ) && cnt === 0 ) {
        menuList[0].active = true;
      }
    },

    setMenuOptions( menuList ) {

      if ( this.isMobileMode ) {
        this.setMobDefaultMenuActive( menuList );
      }

      menuList.forEach( first => {

        // console.log( "first", first )
        if ( this.$validate.isEmpty( first.childTreeNodeList ) ) {
          return;
        }

        if ( '교육활동' === first.name ) {
          first.options = 'is-division';

          first.childTreeNodeList.forEach( second => {
            if ( '과학정보교육' === second.name ) {
              second.options = 'is-primary';
              second.childTreeNodeList.forEach( third => {
                third.options = 'is-primary';
              } )
            }
            if ( '정보영재교육' === second.name ) {
              second.options = 'is-point';
              second.childTreeNodeList.forEach( third => {
                third.options = 'is-point';
              } )
            }
          } )
        }

        if ( '열린행정' === first.name ) {
          first.childTreeNodeList.forEach( second => {
            if ( '업무 자료실' === second.name || '산업안전보건' === second.name ) {
              second.childTreeNodeList.forEach( third => {
                third.options = 'is-gray';
              } )
            }
          } )
        }

        if ( '고객센터' === first.name ) {
          first.childTreeNodeList.forEach( second => {
            if ( '개인정보처리 방침' === second.name ) {
              second.childTreeNodeList.forEach( third => {
                third.options = 'is-gray';
              } )
            }
          } )
        }

        this.setFullPathUrl( first );

        first.childTreeNodeList.forEach( child => {
          this.setFullPathUrl( first, child );
        } )
      } )
    },

    setFullPathUrl( menu, child ) {
      if ( this.$validate.isEmpty( child ) ) {
        menu.fullPathUrl = menu.url;
        return;
      }

      child.fullPathUrl = menu.fullPathUrl + child.url;

      if ( this.$validate.isNotEmpty( child.childTreeNodeList ) ) {
        child.childTreeNodeList.forEach( child2 => {
          this.setFullPathUrl( child, child2 );
        } )
      }
    },

    logout() {
      this.$portalAuth.logout();
    },

    onSubMenuClick( menu, depth2 ) {
      event.preventDefault()
      // console.log("@@@@@@@@@@@@@@ 모바일에서 2depth 누름!!");

      // depth3 없을 경우 페이지 이동
      if ( depth2.childTreeNodeList.length === 0 ) {
        this.onMobMenuToggle( false, '' );
        this.moveSubMenu( menu, depth2, 2 );
      }

      // depth3 있을 경우 class 추가
      this.selected2 = depth2.menuOid;
      this.mobToggle2 = !this.mobToggle2;
    },

    // 모바일 메뉴 Open
    onMobMenuToggle( boolean, overflow ) {
      this.mobMenuSlideYn = boolean;
      document.querySelector( 'html' ).style.overflow = overflow;
      document.body.style.overflow = overflow;
      // document.getElementsByClassName('header').style.zIndex = "2010";
    },

    // 모바일 우측 주 메뉴 로그아웃 관련
    setScreenSize() {
      const vh = window.innerHeight;
      document.documentElement.style.setProperty( '--vh', `${vh}px` );
    },

    isLink( menu ) {
      let result = false;

      if ( this.$validate.isEmpty( menu ) || this.$validate.isEmpty( menu.url ) ) {
        return result;
      }

      if ( this.$constant.MENU_TYPE_FLAG.URL.KEY === menu.menuTypeFlag && -1 < menu.url.indexOf(
        'http' ) ) {
        result = true;
      }

      return result;
    },

    // 마지막 로그인 정보를 보여준다
    getLastLoginDate() {

        return `${ this.loginUser.loginLastDate || '-' } (${ this.loginUser.lastLoginUserIp || '-' })`;
    },

    // 세션 시간을 보여준다
    getSessionTimeout() {
      // 1초에 한번씩 start 호출
      this.polling = setInterval( () => {

        this.resTime = this.$util.sessionTimeUtils.getSessionRestTime();

        if ( 0 >= this.resTime ) {

          clearInterval( this.polling );
          this.$util.rsAlertUtils.rsAlert( { contents: "세션이 종료되었습니다. 로그아웃됩니다." } ).then( () => {
            this.goHome();
          } );
        }

      },1000)
    },

    // 세션 시간 연장, 타이머 리셋은 axiosConfig에서 처리
    resetTimer() {

      this.$axios.post( portalApiUrl.USER.USER.DUMMY, {} );
    },

    // 로그아웃 후 메인으로 이동
    goHome() {
      this.$portalAuth.logout()
    },

    // 특정 해상도 html font-size 조정
    addMediaClass() {
      this.sitemapVisible = true
      document.querySelector( 'html' ).classList.add( 'is-media' );
    },

    // 팝업
    async listPopup() {

      const param = {
        popupViewTypeFlagList: [this.$constant.POPUP_TYPE.VIEW.LIST.KEY,
          this.$constant.POPUP_TYPE.VIEW.GENERAL.KEY]
      };

      await this.$axios.post( portalApiUrl.USER.POPUP.LIST_OPERATION, param ).then( res => {

        if ( res.data ) {

          const popupList = res.data

          //팝업이 에디터 타입인 경우, 인라인 이벤트 핸들러를 이용한 XSS를 막기 위해 인라인 이벤트를 전부 제거합니다.
          popupList.forEach( popup => {
            if ( this.$constant.POPUP_TYPE.CONTENTS.EDITOR === popup?.popupContentsTypeFlag && popup?.contents ) {
              popup.contents = froalaEditorUtil.removeAllInlineEventHandler( popup.contents );
            }
          } );

          let groupBy = _.groupBy( popupList, 'popupViewTypeFlag' );

          if ( groupBy ) {
            this.tempLayerPopupList = _.get( groupBy, this.$constant.POPUP_TYPE.VIEW.GENERAL.KEY );
            this.tempPopupZoneList = _.get( groupBy, this.$constant.POPUP_TYPE.VIEW.LIST.KEY );

          }
        }
      } );

    },
    // 쿠키 상관없이 팝업존 오픈
    openPopup() {
      // 일반 타입 팝업
      if ( this.$validate.isNotEmpty( this.tempLayerPopupList ) ) {
        this.layerPopupList = this.tempLayerPopupList;
        this.layerPopupVisible = true;
      }

      // 리스트 타입 팝업
      if ( this.$validate.isNotEmpty( this.tempPopupZoneList ) ) {
        this.popupZoneList = this.tempPopupZoneList;
      }
    },

    closeLayerPopup() {
      this.layerPopupList = [];
      this.layerPopupVisible = false;
    },

    closePopupZone() {
      this.popupZoneList = [];
    },

  }
}
</script>
<style scoped>
</style>
