import axios from "axios";
import Cookie from "js-cookie";

const PATH_LOGIN = '/portal/user/login/user_login';
const PATH_MAIN = '/portal/user/main/user_main';

const URL_SSO_USER = "/portal/sso/sso_user";
const URL_AUDIT_LOGIN_INSERT = "/api/user/v1/portalUserLoginAuditApi_insert";

export default async function ( { route, redirect, store } ) {

  const KWE_USERID = Cookie.get( "KWE_USERID" );

  let originalPath = route.fullPath;

  if ( !KWE_USERID ) {
	return;
  }


  // 로그인이 되어있을 경우
  if ( $nuxt.$portalAuth.isLogin() ) {

	 // 로그인화면인 경우라면 메인화면으로 이동
	 if ( PATH_LOGIN === originalPath ) {
		 return redirect( PATH_MAIN );
	 }

    return;
  }

  // 스토어에 사용자 아이디나 LUO 쿠키가 있다면 새창으로 접속했을 것으로 판단.
  let isNewWindow = $nuxt.$validate.isNotEmpty( $nuxt.$store.state.common.login.loginUser.userId ) || $nuxt.$validate.isNotEmpty( Cookie.get ("LUO") );

  // 로그인이 안되어있는데 해당 쿠키가 존재한다면
  if ( KWE_USERID ) {

    await handleSsoLogin( );

	// 세션생성후 로그인화면이었다면 메인으로 이동
    if ( PATH_LOGIN === originalPath ) {
		return redirect( PATH_MAIN );
	}

    return;
  }


  /**
   * SSO 로그인 처리 함수
   */
  async function handleSsoLogin( ) {

      const userInfo = await axios.post( URL_SSO_USER, {} );

      if ( $nuxt.$validate.isEmpty( userInfo )
           || $nuxt.$validate.isEmpty( userInfo.data ) ) {

        return;
      }

      loginSuccess( userInfo.data );
  }

  /**
   * 로그인 성공 처리
   */
  function loginSuccess( data ) {

    const loginUser = store.$portalUtil.portalUserUtils.convertUserInfoToLoginUser( data );
    store.dispatch( "common/login/loginUser", loginUser );


	// 새창일때는 이력을 쌓지 않도록 처리
    if ( !loginUser.isNoDuplicateCheck && !isNewWindow ) {
	  //console.log ( "이력 로그 실시" );
      saveAuditLogin();
    }
  }

  /**
   * 로그인 접속정보를 가져와서 로그인 정보 저장
   */
  async function saveAuditLogin() {

    let result = await store.$util.visitUtils.getUserVisitInfo();

    if ( store.$validate.isNotEmpty( result ) ) {
      	await insertAuditLogin( result );
    }
  }

  /**
   * 로그인 접속정보 서버 API 호출
   */
  async function insertAuditLogin( visitInfo ) {

    if ( store.$validate.isEmpty( visitInfo ) ) {
      return;
    }

    const {data} = await store.$axios.post( URL_AUDIT_LOGIN_INSERT, visitInfo );
    store.dispatch( "common/login/setLoginAuditOid", data );

  }
}

